<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#3F033B"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showsnackbar" color="#3F033B" right :timeout="timeout">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: #000">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap>
      <v-flex xs12>
        <v-layout wrap>
          <v-flex xs12 sm3 md4 text-left>
            <span class="mainHeader">Aciding Report</span>
          </v-flex>
          <v-flex xs12 sm3 md2 text-right pl-1 pt-1>
        <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="fromDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="fromDate"
                    label="From"
                    outlined
                    background-color="white"
                    readonly
                    hide-details
                    clearable
          class="rounded-xl"
          dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="fromDate"
                  no-title
                  scrollable
                  @change="$refs.menu.save(fromDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
        </v-flex>
        <v-flex xs12 sm3 md2 text-right pl-1 pt-1>
          <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :return-value.sync="toDate"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="toDate"
                    label="To"
                    readonly
                    background-color="white"
                    outlined
                    hide-details
                    clearable
          class="rounded-xl"
          dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="toDate"
                  no-title
                  scrollable
                  @change="$refs.menu2.save(toDate)"
                >
                  <v-spacer></v-spacer>
                </v-date-picker>
              </v-menu>
        </v-flex>
      <v-flex xs12 sm2 md2 text-right pl-1 pt-1>
        <v-text-field
          background-color="white"
          v-model="keyword"
          clearable
          hide-details="auto"
          color="#b234a9"
          placeholder="Search"
          outlined
          class="rounded-xl"
          dense
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-flex>
      <v-flex xs12 sm2 md2 text-right pl-1 pt-1>
        <v-select clearable
                      outlined
                      hide-details
                      dense
                      background-color="white"
                      class="rounded-xl"
                      v-model="status"
                      :items="acidingStatus"
                      label="Status"
                    ></v-select>
</v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 py-6 text-left>
      
        <v-layout wrap>
          <v-flex xs12 pt-4>
            <v-card
              tile
              class="rounded-lg"
              elevation="0"
              :color="$route.name == 'Dashboard' ? 'transparent' : '#FFFFFF'"
              :flat="$route.name == 'Dashboard' ? true : false"
            >
              <v-card elevation="0">
                <v-layout wrap>
                  <v-flex xs12>
                    <v-layout wrap pa-4 v-if="List2">
                      <v-flex xs12 v-if="List2.length>0">
                        <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th class="text-left subhed"><b>S.No.</b></th>
                            <th class="text-left subhed">
                              <b>Batch Code</b>
                            </th>
                            <th class="text-left subhed"><b>Aciding Date</b></th>
                            <th class="text-left subhed">
                              <span class="subhed"><b>Outbound</b></span
                              ><span class="subheeed">(gm)</span>
                            </th>
                            <th class="text-left">
                              <span class="subhed"><b>Return Date</b></span
                              >
                            </th>
                            <th class="text-left">
                              <span class="subhed"><b>Inbound</b></span
                              ><br/><span class="subheeed">(Thankam)</span>
                            </th>
                            <!-- <th class="text-left">
                              <span class="subhed"><b>Thankam Rate</b></span
                              ><span class="subheeed">(₹)</span>
                            </th> -->
                            <th class="text-left">
                              <span class="subhed"><b>Acidifier</b></span
                              >
                            </th>
                            <th class="text-left">
                              <span class="subhed"><b>Status </b></span
                              >
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(value, i) in List2" :key="i" class="table">
                            <!-- <td>{{ i + 1 }}</td> -->
                            <td>
                              <span>
                                {{ i + 1 }}
                              </span>
                            </td>
                            <td>
                              <span v-if="value.batchNumber"
                                >{{ value.batchNumber }}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.batchDate">
                                {{ formatDate(value.batchDate) }}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.totalOldGoldNetWeight">{{
                                value.totalOldGoldNetWeight
                              }}</span>
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.acidingOldGoldId">
                                {{ formatDate(value.acidingOldGoldId.thankamReturnDate) }}</span
                              >
                              <span v-else>-</span>
                            </td>
                            <td>
                              <span v-if="value.acidingOldGoldId">{{
                                (value.acidingOldGoldId.thankam).toFixed(3)
                              }}</span>
                              <span v-else>0</span>
                            </td>
                            <!-- <td>
                              <span v-if="value.acidingOldGoldId.goldRate">{{
                                value.acidingOldGoldId.goldRate
                              }}</span>
                              <span v-else>0</span>
                            </td> -->
                            <td>
                              <div v-if="value.acidifier">
                                <span v-if="value.acidifier.supplierName"
                                >{{ value.acidifier.supplierName }}</span
                              >
                              </div>
                              <div v-else>
                                <span v-if="value.acidifierType"
                                >{{ value.acidifierType }}</span
                              >
                                <span v-else>-</span>
                              </div>
                             
                            </td>
                            <td>
                              <div v-if="value.status">
                              <span v-if="value.status=='Batched'"
                                >OUTBOUND</span
                              >
                              <span v-if="value.status=='Acidified'"
                                > INBOUND</span
                              >
                            </div>
                            <div v-else>-</div>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                      </v-flex>
            <v-flex xs12 v-else pa-4 text-center class="tablefont1"> No data found </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-card>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout wrap v-if="List2">
      <v-flex xs12 pt-4 v-if="List2.length>0">
        <v-pagination
                small
                color="#3F053C"
                v-model="page"
                :length="Pagelength"
              >
              </v-pagination>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      appLoading: false,
      ServerError: false,
      showsnackbar: false,
      timeout: 2000,
      msg: null,
      to: null,
      page:1,
      Pagelength:null,
      menu1: false,
      menu2: false,
      menu: false,
      supplierList: [],
      sName:"",
      List2: [],
      fromDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
        toDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
        keyword:"",
        acidingStatus: ["Inbound","Outbound"],
        status:"",
    };
  },

  watch: {
    fromDate() {
      this.getList();
    },
    toDate() {
      this.getList();
    },
    page() {
      this.getList();
    },
    status(){
      this.getList();
    },
    keyword(){
      this.getList();
    }
  },
  mounted() {
    this.getList();
    this.getSupplier();
  },
  methods: {
    getList() {
      if(this.status=='Inbound'){
        this.status = "Acidified"
      }
      else  if(this.status=='Outbound'){
        this.status = "Batched"
      }
      axios({
        url: "/oldgoldbatchlist/basedonstatus",
        method: "get",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          count: 20,
          page: this.page,
          sName: this.sName,
          from: this.fromDate,
          to: this.toDate,
          keyword: this.keyword,
          status: this.status,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.List2 = response.data.data;
            this.Pagelength = Math.ceil(response.data.count / 20);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    getSupplier() {
      this.appLoading = true;
      axios({
        method: "POST",
        url: "/stock/supplier/getlist",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status == true) {
            this.supplierList = response.data.supplierlist;
          } else {
            this.snackbar = true;
            this.msg = response.data.msg;
          }

          // this.msg = response.data.msg;

          // this.snackbar = true;
        })
        .catch((err) => {
          this.appLoading = false;
          console.log(err);
        });
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      // var hours = dt.getHours();
      // var minutes = dt.getMinutes();
      dt = dt.toString();
      // var ampm = hours >= 12 ? "pm" : "am";
      // hours = hours % 12;
      // hours = hours ? hours : 12;
      // minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      //  +
      // " , " +
      // hours +
      // ":" +
      // minutes +
      // " " +
      // ampm;

      return strTime;
    },
  },
};
</script>
